<script setup>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { reactive } from "vue";

// サポートしてるかチェック
if (!mapboxgl.supported()) {
  alert("Your browser does not support Mapbox GL");
} else {
  console.log("Your browser supported Mapbox GL");
}

</script>

<template>
  <v-app>
    <v-container fluid>
        <v-row align="center">
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
        </v-col>
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
            <v-select
            :items="items"
            label="地図の縦横比"
            dense
            solo
            v-model="item"
            ></v-select>
        </v-col>
        </v-row>
        <!-- マップをここに表示 -->
        <div style="text-align: center;" width="100%">
        <center>
          <div id="map" class="map"/>
        </center>
        </div>
        <br/>
      <v-btn block v-on:click="btn" height="80px" width="450px">概略地図に変換</v-btn>
    </v-container>
    </v-app>
</template>

<style scoped>
/* マップの高さは必ず指定 */
.map {
  height: 300px;
  width: 300px;
}
.marker {
  background-image: url('target.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
</style>

<script>
// var g_map;
let target = null;
let starts = [];
let isPost=null;
let host = "https://daitai-konohen.a.takoyaki3.com";
// let host = "http://hackday.takoyaki3.com:8000";

const log = function(){
  if (isPost!=null) {
    console.log("test",isPost);
    const query = {
      id:isPost
    }
    const url = host+'/done?query='+JSON.stringify(query);
    console.log(url)
    fetch(url)
      .then(function (data) {
        return data.json();
      })
      .then(function (json) {
        console.log(json)
        if (json.status == "done"){
          location.href = host+'/'+isPost+".png";
        }
      });
  }
};

setInterval(log, 3000);

export default {
  data: () => ({
    items:['600x600','600x900','900x600','300x300','300x450','450x300','450x450'],
    item:'300x300',
    map:null,
    mapInfo: reactive({
      style: "mapbox://styles/mapbox/outdoors-v10",
      lng: 139.736990,
      lat: 35.679576,
      zoom: 14, // 0 - 22
      pitch: 0, // 0 - 85
      bearing: 0, // 0 - 360
      bounds: {
        west: null,
        south: null,
        east: null,
        north: null,
      },
    }),
    accessToken:"pk.eyJ1IjoidGFrb3lha2kzIiwiYSI6ImNrN2p3OTFidzB3MWQzb29hMHlpNDE5bHIifQ.DRmeCz-ABDUgZOT7bqsWOA"
  }),
  methods:{
    btn:function(){
      // alert('北西：'+this.map.getBounds().getNorthWest().lat+"\n"+
      // '南東：'+this.map.getBounds().getSouthEast())

      const ne = this.map.getBounds().getNorthEast();
      const sw = this.map.getBounds().getSouthWest();

      const query = {
        "max_lat":ne.lat,
        "max_lon":ne.lng,
        "min_lat":sw.lat,
        "min_lon":sw.lng,
        "target":target,
        "starts":starts
      }

      const url = host+'/req?query='+JSON.stringify(query);
      console.log(url)
      fetch(url)
        .then(function (data) {
          return data.json();
        })
        .then(function (json) {
          console.log(json)
          isPost = json.id;
        });
    },
  },
  watch:{
    item: function(item){
      console.log(item)
      // HTML要素を取得
      var element = document.getElementById("map");

      // 要素にCSSを適用
      element.style.height = Number(item.split('x')[0])+"px";
      element.style.width =  Number(item.split('x')[1])+"px";
    }
  },
  mounted: function(){

    // トークンをセット
    mapboxgl.accessToken = this.accessToken;

    // マップ
    this.map = new mapboxgl.Map({
      // マップを表示するエレメントのID
      container: "map",
      // 表示するマップのタイプ
      style: this.mapInfo.style,
      // マップの中心
      center: [this.mapInfo.lng, this.mapInfo.lat], // lng, lat
      // どのぐらいの倍率で表示するか
      zoom: this.mapInfo.zoom, // default: 0
      // 範囲を指定して表示もできる、この場合`zoom`はいらない
      // bounds: [
      //    [mapInfo.bounds.west, mapInfo.bounds.south], // [west, south]
      //    [mapInfo.bounds.east, mapInfo.bounds.north], // [east, north]
      // ],
      // 傾き 0から85
      pitch: this.mapInfo.pitch,
      // 方位 北から反時計回りに0から360
      bearing: this.mapInfo.bearing,
      // アンチエイリアス
      antialias: true,
      // マップボックス情報
      attributionControl: false,
    });

    const map = this.map
    this.map.on('click', function(e) {

      //クリック位置経緯度取得
      const lat = e.lngLat.lat;
      const lng = e.lngLat.lng;

      new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
      if (target == null) {
        target = [lng, lat];
      } else {
        starts.push([lng, lat])
      }
    });
    // 要素にCSSを適用
    let element = document.getElementById("map");
    element.style.height = "300px";
    element.style.width =  "300px";
  }
}
</script>
